export default {
  notebird: {
    title: 'Notebird',
    host: {
      prod: 'beta.notebird.app',
      staging: 'beta.notebird.dev',
      dev: 'localhost:3000',
    },
  },
  kidcard: {
    title: 'Kidcard',
    host: {
      prod: 'my.kidcard.app',
      staging: 'kidcard.notebird.dev',
      dev: 'localhost:3001',
    },
  },
} as const;
// export default [
//   {
//     // Notebird
//     key: 'notebird',
//     title: 'Notebird',
//     host: {
//       prod: 'my.notebird.app',
//       staging: 'staging.notebird.dev',
//       dev: 'localhost:3000',
//     },
//   },
//   {
//     // Kidcard
//     key: 'kidcard',
//     title: 'Kidcard',
//     host: {
//       prod: 'my.kidcard.app',
//       staging: 'kidcard.notebird.dev',
//       dev: 'localhost:3001',
//     },
//   },
// ] as const;
