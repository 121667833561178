import { lazy, useEffect, useState } from 'react';

import { type User, onAuthStateChanged } from 'firebase/auth';

// import configs from './configs';
import configSpecs from './lib/configSpecs';
import { auth } from './lib/firebase';

const AuthedLayout = lazy(() => import('@/app/AuthedLayout'));
const UnauthedLayout = lazy(() => import('@/app/UnauthedLayout'));

/** Determine auth status, then pick which layout to load */
export default function AppLoader() {
  console.log('env', import.meta.env);
  const config = useInitConfig();

  const [authUser, setAuthUser] = useState<User | null | undefined>(undefined);
  useEffect(() => onAuthStateChanged(auth, setAuthUser), []);

  // const configKey = getConfigKey();
  // console.log('configKey', configKey);

  if (authUser === undefined || config === undefined) return null;
  return authUser ? (
    <>
      {/* {<config.logo />} */}
      <AuthedLayout />
    </>
  ) : (
    <UnauthedLayout />
  );
  // return authUser ? <AuthedLayout /> : <UnauthedLayout />;
}

function useInitConfig() {
  const [state, setState] = useState<
    (typeof configSpecs)[keyof typeof configSpecs] | undefined
  >(undefined);
  useEffect(() => {
    const windowHost = window.location.host;
    for (const [key, configSpec] of Object.entries(configSpecs)) {
      for (const [env, host] of Object.entries(configSpec.host)) {
        if (host === windowHost) {
          console.log({ env });
          // Populate document title
          document.title = configSpec.title;
          // console.log(config.title);
          // Set Favicon
          // const link = document.createElement('link');
          // link.rel = 'icon';
          // link.type = 'image/svg+xml';
          // link.href = `/icons/${config.key}.svg`;
          // document.head.appendChild(link);

          // Set logo
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          // const logo = lazy(() => import(`./img/${configSpec.title}Logo.tsx`));

          // return { env, ...config, logo };
          setState(configSpec);
        }
      }
      console.log({ key, config: configSpec, windowHost });
    }
    return;
  }, []);
  return state;
}
// function useInitConfig() {

//   return useMemo(() => {
//     const windowHost = window.location.host;

//     for (const config of configs) {
//       for (const [env, host] of Object.entries(config.host)) {
//         if (host === windowHost) {
//           // Populate document title
//           document.title = config.title;
//           // console.log(config.title);
//           // Set Favicon
//           // const link = document.createElement('link');
//           // link.rel = 'icon';
//           // link.type = 'image/svg+xml';
//           // link.href = `/icons/${config.key}.svg`;
//           // document.head.appendChild(link);

//           // Set logo
//           // eslint-disable-next-line @typescript-eslint/no-unsafe-return
//           const logo = lazy(() => import(`./img/${config.title}Logo.tsx`));
//           return { env, ...config, logo };
//         }
//       }
//       // const hosts = Object.values(config.host);
//       // if (hosts.includes(host)) {
//       //   document.title = config.title;
//       //   return config;
//       // }
//     }

//     // return { env: 'dev', logo: NotebirdLogo, ...configs[0] };
//   }, []);
// }

// const CONFIG_KEYS = {
//   default: ['my.notebird.app'],
//   dev: ['localhost:3000', 'staging.notebid.dev'],
// };

// function getConfigKey() {
//   const { host } = window.location;
//   console.log('host', host);

//   for (const [key, values] of Object.entries(CONFIG_KEYS)) {
//     if (values.includes(host)) return key;
//   }

//   return 'default';
// }
