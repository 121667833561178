export default {
  Notebird: 'Notebird',

  // Notebird Native Elements
  organization: 'Organization',
  organizations: 'Organizations',
  location: 'Location',
  locations: 'Locations',
  group: 'Group',
  groups: 'Groups',
  user: 'User',
  users: 'Users',
  person: 'Person',
  people: 'People',
  activity: 'Activity',
  update: 'Update',
  updates: 'Updates',
  milestone: 'Milestone',
  milestones: 'Milestones',
  task: 'Task',
  tasks: 'Tasks',

  // Main Actions
  add: 'Add',
  search: 'Search',
};
