import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { Timestamp, getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const config = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const fbApp = initializeApp(config);

// Export libs
export const auth = getAuth(fbApp);
export const db = getFirestore(fbApp);
export const functions = getFunctions(fbApp);

// Timestamp helpers
export interface FlattenedTimestamp {
  _seconds: number;
  _nanoseconds: number;
}
/** Convert all Firestore timestamps into their flattened counterparts */
export type FlattenedTimestamps<T> = T extends firebase.firestore.Timestamp
  ? FlattenedTimestamp
  : T extends object
  ? {
      [P in keyof T]: FlattenedTimestamps<T[P]>;
    }
  : T;
/** Revert all flattened Firestore timestamps into their unflattened counterparts */
export type UnflattenedTimestamps<T> = T extends FlattenedTimestamp
  ? firebase.firestore.Timestamp
  : T extends object
  ? {
      [P in keyof T]: UnflattenedTimestamps<T[P]>;
    }
  : T;
export const convertToTimestamp = (
  timestamp: FlattenedTimestamp
): firebase.firestore.Timestamp => {
  return new Timestamp(timestamp._seconds, timestamp._nanoseconds);
};

/** Recursively loop through a data object,
 *  making sure previously serialized timestamps are restored  */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function restoreTimestamps<T extends Record<string, any>>(
  input: T
): UnflattenedTimestamps<T> {
  if (!input) return input;
  if (typeof input !== 'object') return input as UnflattenedTimestamps<T>;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
  if (Array.isArray(input)) return input.map(restoreTimestamps) as any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const obj: Record<string, any> = { ...input };
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const value = obj[key];
      if (!value) continue;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (
        typeof value?._seconds === 'number' &&
        typeof value?._nanoseconds === 'number'
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        obj[key] = convertToTimestamp(value);
      } else if (typeof value === 'object') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        obj[key] = restoreTimestamps(value);
      }
    }
  }
  return obj as UnflattenedTimestamps<T>;
}
